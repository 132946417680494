<template>
  <div class="card">
    <div class="card-body">
      <Sidepanel @selected="onConfigItemSelected" />
      <div class="inbox-rightbar">
        <!--              <Toolbar />-->
        <template v-if="selectedConfig">

          <h4 class="card-title text-danger">{{selectedConfig['name']}}</h4>

          <template v-if="selectedConfig['id'] === 'rek_sch'">
              <rek-sch></rek-sch>
          </template>
          <template v-else-if="selectedConfig['id'] === 'deb_num'">
              <deb-num></deb-num>
          </template>
          <template v-else-if="selectedConfig['id'] === 'dag_soort'">
            <dag-soort></dag-soort>
          </template>
          <template v-else-if="selectedConfig['id'] === 'bet_cond'">
            <bet-cond></bet-cond>
          </template>
        </template>

      </div >

    </div>
    <!-- card -->
  </div>
</template>

<script>

import Sidepanel from "./sidepanel-unit-4";
import rekSch from "./unit-4-configs/rek-sch";
import debNum from "./unit-4-configs/deb-num";
import dagSoort from "./unit-4-configs/dag-soort"
import BetCond from "./unit-4-configs/bet-cond";

export default {
  name: "configs",
  components: {
    BetCond,
    Sidepanel,
    rekSch,
    debNum,
    dagSoort
  },
  data() {
    return {
      selectedConfig: null,
    }
  },
  methods: {
    onConfigItemSelected(config){
      // console.log(config)
        this.selectedConfig = {
          name: config['name'],
          index: config['index'],
          id: config['id'],
        }
    }
  }
}
</script>

<style scoped>

</style>