<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <form action="#" @submit.prevent="handleExcelSubmit">
          <div class="form-group mt-3">
            <vue-dropzone
                id="file"
                ref="file"
                :use-custom-slot="true"
                :options="dropzoneOptions"
            >
              <div class="dz-message needsclick">
                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                <h3>Drop csv / xlsx file</h3>
              </div>
            </vue-dropzone>
          </div>

          <div class="text-center">
            <button type="submit" class="btn btn-primary">Submit Now</button>
          </div>
        </form>
      </div>
    </div>
    <hr>
    <template v-if="configs">
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="row mb-2">
            <div class="col-sm-6 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Display&nbsp;
                  <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select
                  >&nbsp;items
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-6 col-md-6">
              <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <b-table
              table-class="table table-centered w-100"
              thead-tr-class="bg-light"
              :items="tableData"
              :fields="fields"
              :responsive="true"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >
            <template #cell(action)="data">
              <a href="#" @click.prevent="assignConfigField(data.item)">Assign</a>
            </template>

            <template #cell(status)="data">

              <template v-if="data.item['assigned_to'].length !== 0">
                <span class="text-success"> Assigned </span>
              </template>
              <template v-else>
                Not Assigned
              </template>

            </template>

          </b-table>
          <div class="row">
            <div class="col">
              <div
                  class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded">
                  <!-- pagination -->
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <template v-if="selectedRecord">
          <b-modal id="modal" title="Assign to a configuration" size="lg" scrollable @ok="submitAssignToConfigField">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{ selectedRecord['displayLabel'] }}</label>
                  <input type="text" class="form-control" disabled :value="selectedRecord['displayValue']">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Select configuration type</label>
                  <select class="form-control" v-model="selectedConfigType" @change="getConfigByConfigType">
                    <option value="">-- select from --</option>
                    <option value="providers">Providers configuration</option>
                    <option value="rentals">Rentals configuration</option>
                    <option value="service_charges">Services charges configuration</option>
                    <option value="receipts">Receipts configuration</option>
                    <option value="extra">Extra</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6" v-if="showProviderConfigType">
                <div class="form-group">
                  <label>Select provider</label>
                  <select class="form-control" v-model="selectedProviderId" @change="getConfigByConfigType">
                    <option :value="provider['id']" v-for="provider in providers">{{ provider['name'] }}</option>
                  </select>
                </div>
              </div>

              <template v-if="systemConfig && selectedConfigType !== ''">
                <div class="col-md-12">
                  <hr>
                </div>
                <div class="col-md-6" v-if="systemConfig['calculatedFields']">
                  <div class="form-group">
                    <label class="text-success">Calculated Income Fields</label>
                    <select class="form-control" v-model="selectedCalcField"
                            @change="onCalcTaxFieldsChange(false,'calc')">
                      <option value="">-- choose one --</option>
                      <option :value="field"
                              v-for="field in systemConfig['calculatedFields'].filter((e) => e['fieldType'] === 'income')">
                        {{ field['fieldName'] }} ( {{ field['fieldType'] }} )
                        (
                        {{
                          (field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''
                        }}
                        {{ (field['weeklyPayment'] && !field['fAdministration']) ? 'Used in weekly payment' : '' }}
                        {{ (!field['weeklyPayment'] && field['fAdministration']) ? 'Used in f. administration' : '' }}
                        )
                      </option>
                    </select>

                  </div>
                </div>
                <div class="col-md-6" v-if="systemConfig['calculatedFields']">
                  <div class="form-group">
                    <label class="text-danger">Calculated Cost Fields</label>
                    <multiselect
                        :options="systemConfig['calculatedFields'].filter((e) => e['fieldType'] === 'cost')"
                        :multiple="true" :show-labels="false" v-model="costMultiSelectOpt"
                        :hideSelected="true" :max="1000"
                        @select="onCalcTaxFieldsChange($event,'calc')"
                        @remove="removeOption"
                        track-by="fieldName"
                        :custom-label="costName">
                    </multiselect>

<!--                    <select class="form-control" v-model="selectedCalcField"-->
<!--                            @change="onCalcTaxFieldsChange(false,'calc')">-->
<!--                      <option value="">&#45;&#45; choose one &#45;&#45;</option>-->
<!--                      <option :value="field"-->
<!--                              v-for="field in systemConfig['calculatedFields'].filter((e) => e['fieldType'] === 'cost')">-->
<!--                        {{ field['fieldName'] }} ( {{ field['fieldType'] }} )-->
<!--                        (-->
<!--                        {{-->
<!--                          (field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''-->
<!--                        }}-->
<!--                        {{ (field['weeklyPayment'] && !field['fAdministration']) ? 'Used in weekly payment' : '' }}-->
<!--                        {{ (!field['weeklyPayment'] && field['fAdministration']) ? 'Used in f. administration' : '' }}-->
<!--                        )-->
<!--                      </option>-->
<!--                    </select>-->

                  </div>
                </div>
                <div class="col-md-6" v-if="systemConfig['calculatedFields']">
                  <div class="form-group">
                    <label class="text-success">Tax Income (Upper) fields</label>
                    <select class="form-control" v-model="selectedTaxField"
                            @change="onCalcTaxFieldsChange(false,'tax')">
                      <option value="">-- choose one --</option>
                      <option :value="field"
                              v-for="field in systemConfig['taxFields'].filter((e) => e['refFieldType'] === 'income'  && e['taxType'] === 'upper')">
                        BTW {{ field['refFieldName'] }}
                        ( {{ field['taxType'] }} tax )
                        ( {{ field['refFieldType'] }} )
                        (
                        {{
                          (field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''
                        }}
                        {{ (field['weeklyPayment'] && !field['fAdministration']) ? 'Used in only weekly payment' : '' }}
                        {{
                          (!field['weeklyPayment'] && field['fAdministration']) ? 'Used in  only f. administration' : ''
                        }}
                        )
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6" v-if="systemConfig['calculatedFields']">
                  <div class="form-group">
                    <label class="text-danger">Tax Cost (Upper) fields</label>

                    <multiselect
                        :options="systemConfig['taxFields'].filter((e) => e['refFieldType'] === 'cost'  && e['taxType'] === 'upper')"
                        :multiple="true" :show-labels="false" v-model="multiSelectOpt"
                        :hideSelected="true" :max="1000"
                        @select="onCalcTaxFieldsChange($event,'tax')"
                        @remove="removeOption"
                        track-by="refFieldName"
                        :custom-label="nameWithLang">
                    </multiselect>
                    <!--                    <select class="form-control" v-model="selectedTaxField" @change="onCalcTaxFieldsChange(false,'tax')">-->
                    <!--                      <option value="">&#45;&#45; choose one &#45;&#45;</option>-->
                    <!--                      <option :value="field"-->
                    <!--                              v-for="field in systemConfig['taxFields'].filter((e) => e['refFieldType'] === 'cost'  && e['taxType'] === 'upper')">-->
                    <!--                        BTW {{ field['refFieldName'] }}-->
                    <!--                        ( {{ field['taxType'] }} tax )-->
                    <!--                        ( {{ field['refFieldType'] }} )-->
                    <!--                        (-->
                    <!--                        {{-->
                    <!--                          (field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''-->
                    <!--                        }}-->
                    <!--                        {{ (field['weeklyPayment'] && !field['fAdministration']) ? 'Used in only weekly payment' : '' }}-->
                    <!--                        {{-->
                    <!--                          (!field['weeklyPayment'] && field['fAdministration']) ? 'Used in  only f. administration' : ''-->
                    <!--                        }}-->
                    <!--                        )-->
                    <!--                      </option>-->
                    <!--                    </select>-->
                  </div>
                </div>

                <!--                Tax upper / lower -->
                <div class="col-md-6" v-if="systemConfig['calculatedFields']">
                  <div class="form-group">
                    <label class="text-success">Tax Income ( Lower ) fields</label>
                    <select class="form-control" v-model="selectedTaxField"
                            @change="onCalcTaxFieldsChange(false,'tax')">
                      <option value="">-- choose one --</option>
                      <option :value="field"
                              v-for="field in systemConfig['taxFields'].filter((e) => e['refFieldType'] === 'income' && e['taxType'] === 'lower')">
                        BTW {{ field['refFieldName'] }}
                        ( {{ field['taxType'] }} tax )
                        ( {{ field['refFieldType'] }} )
                        (
                        {{
                          (field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''
                        }}
                        {{ (field['weeklyPayment'] && !field['fAdministration']) ? 'Used in only weekly payment' : '' }}
                        {{
                          (!field['weeklyPayment'] && field['fAdministration']) ? 'Used in  only f. administration' : ''
                        }}
                        )
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6" v-if="systemConfig['calculatedFields']">
                  <div class="form-group">
                    <label class="text-danger">Tax Cost (Lower) fields</label>
                    <select class="form-control" v-model="selectedTaxField"
                            @change="onCalcTaxFieldsChange(false,'tax')">
                      <option value="">-- choose one --</option>
                      <option :value="field"
                              v-for="field in systemConfig['taxFields'].filter((e) => e['refFieldType'] === 'cost'  && e['taxType'] === 'lower')">
                        BTW {{ field['refFieldName'] }}
                        ( {{ field['taxType'] }} tax )
                        ( {{ field['refFieldType'] }} )
                        (
                        {{
                          (field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''
                        }}
                        {{ (field['weeklyPayment'] && !field['fAdministration']) ? 'Used in only weekly payment' : '' }}
                        {{
                          (!field['weeklyPayment'] && field['fAdministration']) ? 'Used in  only f. administration' : ''
                        }}
                        )
                      </option>
                    </select>
                  </div>
                </div>
              </template>
              <div class="col-md-12">
                <hr>
                <div class="form-group">
                  <label class="text-info">Corresponding assigned config fields</label>
                  <b-card>
                    <b-card-text>
                      <template v-if="selectedConfigFieldsForAssignment.length === 0">
                        No fields assigned yet
                      </template>
                      <template v-else>
                        <template v-for="(payload, index) in selectedConfigFieldsForAssignment">

                          <a href="#" @click.prevent="removeConfigAssignment(payload,index)">
                          <span class="badge badge-pill badge-soft-primary p-2 m-1">
                              <template v-if="payload['selectedConfigType'] === 'tax'">
                                  BTW {{ payload['selectedConfig']['refFieldName'] }}
                                  ( {{ payload['selectedConfig']['taxType'] }} tax )
                                  ( {{ payload['selectedConfig']['refFieldType'] }} )
                                  (
                                  {{
                                  (payload['selectedConfig']['weeklyPayment'] && payload['selectedConfig']['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''
                                }}
                                  {{
                                  (payload['selectedConfig']['weeklyPayment'] && !payload['selectedConfig']['fAdministration']) ? 'Used in only weekly payment' : ''
                                }}
                                  {{
                                  (!payload['selectedConfig']['weeklyPayment'] && payload['selectedConfig']['fAdministration']) ? 'Used in  only f. administration' : ''
                                }}
                                  )
                              </template>
                              <template v-else-if="payload['selectedConfigType'] === 'calc'">
                                {{ payload['selectedConfig']['fieldName'] }} ( {{
                                  payload['selectedConfig']['fieldType']
                                }} )
                               (
                                {{
                                  (payload['selectedConfig']['weeklyPayment'] && payload['selectedConfig']['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''
                                }}
                                {{
                                  (payload['selectedConfig']['weeklyPayment'] && !payload['selectedConfig']['fAdministration']) ? 'Used in weekly payment' : ''
                                }}
                                {{
                                  (!payload['selectedConfig']['weeklyPayment'] && payload['selectedConfig']['fAdministration']) ? 'Used in f. administration' : ''
                                }}
                               )
                              </template>
                            <i class="fas fa-trash-alt ml-2 mr-1"></i>
                          </span>
                          </a>
                        </template>
                      </template>


                    </b-card-text>
                  </b-card>

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Select sheets to apply</label>
                  <select class="form-control" @change="onSheetSelected" v-model="selectsSheetsToApplyValue">
                    <option value="">-- choose one ---</option>
                    <option value="verkoop">use in verkoop sheet</option>
                    <option value="inkoop">use in inkoop sheet</option>
                    <option value="memoriaal">use in memoriaal sheet</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="text-danger">selected sheets</label>
                  <b-card>
                    <b-card-text>
                      <template v-if="selectedSheets.length === 0">
                        No fields assigned yet
                      </template>
                      <template v-else>
                        <template v-for="(item, index) in selectedSheets">

                          <a href="#" @click.prevent="removeSelectedSheet(index)">
                          <span class="badge badge-pill badge-soft-primary p-2 m-1">
                                {{ item }}
                            <i class="fas fa-trash-alt ml-2 mr-1"></i>
                          </span>
                          </a>
                        </template>
                      </template>
                    </b-card-text>
                  </b-card>
                </div>
              </div>

            </div>
          </b-modal>
        </template>
      </div>
    </template>

  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";


export default {
  name: "reekning",
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect
  },
  data() {
    return {
      dropzoneOptions: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".csv,.xlsx",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 1,
      },
      selectsSheetsToApplyValue: '',
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 200, 500, 1000, 1500, 2000],
      filter: null,
      filterOn: [],
      sortBy: "registeredAt",
      sortDesc: false,
      fields: [],
      tableData: [],
      selectedRecordIndex: null,
      selectedRecord: null,
      selectedConfigType: '',
      showProviderConfigType: false,
      selectedProviderId: '',
      selectedTaxField: {},
      selectedCalcField: {},
      calcOrTaxSelected: null,
      data: null,
      selectedConfigFieldsForAssignment: [],
      selectedSheets: [],
      multiSelectOpt: [],
      costMultiSelectOpt:[]
    }
  },
  computed: {
    providers() {
      return this.$store.getters.GET_PROVIDERS
    },
    systemConfig() {
      const c = this.$store.getters.GET_SELECTED_SYSTEM_CONFIG
      console.log('config in systemConfig', c)
      return c;

    },
    configs() {
      const data = this.$store.getters.GET_SELECTED_UNIT4_CONFIG
      // console.log('config data', data)
      if (data) {
        const header = data.header
        const body = data.body
        this.data = data.body
        const assigned = data.assigned

        this.fields = header.map((e) => {
          //{"index":0,"name":"Rekening","tag":"rekening"}
          return {
            label: e['name'],
            key: e['tag'],
            sortable: true,
          }
        })

        this.fields.push({
          label: 'status',
          key: 'status',
          sortable: true,
        })
        this.fields.push('action')

        this.tableData = body.map((row, index) => {

          //[{ age: 40, first_name: 'Dickerson', last_name: 'Macdonald' }, { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },],

          let obj = {};

          for (let i = 0; i < row.length; i++) {
            const cell = row[i];
            const key = cell['tag']

            let value = cell['value'];
            if (key === "rek-num") {
              value = this.$options.filters.to4DigitsLeadingZeros(value); /// make sure rek sch is displaying in 4 digits
            }
            obj[key] = value;
          }

          obj['record'] = row;
          obj['displayLabel'] = row[0]['fieldName']
          obj['displayValue'] = row[0]['value']
          obj['index'] = index
          obj['assigned_data'] =[];

          // Object.keys(assigned).forEach((e)=>{
          //
          //   console.log(assigned[e],'object')
          // })
          let assigned_data = Object.keys(assigned).map((key) => assigned[key]);


          obj['assigned_to'] = assigned_data.filter(e => e['recordIndex'] === index)
          // obj['sheets'] = row

          return obj;


        })

        // console.log('tableData', this.tableData)

      }

      return data;
    },

    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  methods: {
    selectOption(val) {
      this.$nextTick(() => {
        if (val.index == -1) {
          this.selectAll = !this.selectAll;
          this.selectFnx(this.selectAll)
          return;
        }
        this.selectedRekSchs.push(val.value);
      });
    },
    removeOption(val) {
      const foundIndex = this.selectedConfigFieldsForAssignment.findIndex((e) => e.selectedConfig === val);
      if (foundIndex !== -1) {
        this.selectedConfigFieldsForAssignment.splice(foundIndex, 1);
        if (this.selectedConfigFieldsForAssignment.length === 0) {
          this.selectedSheets = [];
        }
      }
    },
    selectFnx(bool) {
      if (bool) {
        this.multiSelectOpt = this.rekSchsArr.filter((e) => e.index != -1);
      } else {
        this.multiSelectOpt = [];
      }
      this.selectedRekSchs = this.multiSelectOpt.map(e => e.value);
    },
    nameWithLang(field) {
      return `BTW ${field['refFieldName']}
      ( ${field['taxType']} tax )
      ( ${field['refFieldType']} )
      (${(field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''}
       ${(field['weeklyPayment'] && !field['fAdministration']) ? 'Used in only weekly payment' : ''}
       ${(!field['weeklyPayment'] && field['fAdministration']) ? 'Used in  only f. administration' : ''}
       )`
    },
    costName(field) {
      return `${field['fieldName']} ( ${field['fieldType']} )
                        (${(field['weeklyPayment'] && field['fAdministration']) ? 'Used in both weekly payment and  f. administration' : ''}
                        ${(field['weeklyPayment'] && !field['fAdministration']) ? 'Used in weekly payment' : ''}
                        ${(!field['weeklyPayment'] && field['fAdministration']) ? 'Used in f. administration' : ''}
                        )`
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getConfigByConfigType() {
      if (this.selectedConfigType === '') {
        return;
      }

      if (this.selectedConfigType === 'providers' && this.selectedProviderId === '') {
        this.showProviderConfigType = true
        this.selectedTaxField = {}
        this.selectedCalcField = {}
        return;
      }

      if (this.selectedConfigType !== 'providers') {
        this.selectedProviderId = ''
        this.showProviderConfigType = false
        this.selectedTaxField = {}
        this.selectedCalcField = {}
      }

      const payload = {
        'type': this.selectedConfigType,
        'provider_id': this.selectedProviderId,
      }

      console.log('payload', payload)

      this.$store.dispatch('getSelectedSystemConfig', payload)

    },

    onSheetSelected(event) {
      const item = event.target.value;
      console.log(item);
      if (item === '') {
        this.selectsSheetsToApplyValue = ''
        return
      }
      const found = this.selectedSheets.find(e => e === item);
      if (found) {
        this.selectsSheetsToApplyValue = ''
        return;
      }
      this.selectedSheets.push(item)
      this.selectsSheetsToApplyValue = ''

    },


    onCalcTaxFieldsChange(val, type) {
      const record = this.selectedRecord['record'];
      if (val && type ==='tax') {
        this.selectedTaxField = val
      }
      else if (val && type ==='calc') {
        this.selectedCalcField = val
      }
      const foundIndex = this.selectedConfigFieldsForAssignment.findIndex(e =>
          e['selectedConfig']['fieldName'] === this.selectedCalcField['fieldName']
          && e['selectedConfig']['fieldType'] === this.selectedCalcField['fieldType']
          && e['selectedConfig']['fAdministration'] === this.selectedCalcField['fAdministration']
          && e['selectedConfig']['weeklyPayment'] === this.selectedCalcField['weeklyPayment']
      )
      console.log('existing field', foundIndex)
      if (foundIndex !== -1) {
        this.selectedCalcField = ''
        this.selectedTaxField = ''
        return;
      }


      if (type === 'calc') {
        // this.selectedTaxField = {}
        // this.calcOrTaxSelected = 'calc';

        this.selectedConfigFieldsForAssignment.push({
          'selectedConfig': this.selectedCalcField,
          'src': this.selectedConfigType,
          'record': record,
          'recordIndex': this.selectedRecordIndex,
          'selectedConfigType': 'calc',
          'type': 'rek-sch',
          'payload1': '',
          'payload2': ''
        })

      } else if (type === 'tax') {
        // this.selectedCalcField = {}
        // this.calcOrTaxSelected = 'tax';
        // const foundIndex = this.selectedConfigFieldsForAssignment.findIndex(e => e['selectedConfig']['fieldName'] === this.selectedTaxField['fieldName'])
        // console.log('existing tax', foundIndex)
        // if(foundIndex !== -1){
        //   return;
        // }

        this.selectedConfigFieldsForAssignment.push({
          'selectedConfig': this.selectedTaxField,
          'src': this.selectedConfigType,
          'record': record,
          'recordIndex': this.selectedRecordIndex,
          'selectedConfigType': 'tax',
          'type': 'rek-sch'
        })

      }

      this.selectedCalcField = ''
      this.selectedTaxField = ''
    },

    removeConfigAssignment(val, index) {
      console.log(val, index)
      const foundIndex = this.multiSelectOpt.findIndex((e) => e.refFieldName === val.selectedConfig.refFieldName);
      const costIndex = this.costMultiSelectOpt.findIndex((e) => e.fieldName === val.selectedConfig.fieldName);
      if (foundIndex !== -1) {
        this.multiSelectOpt.splice(foundIndex, 1);
      }
      if (costIndex !== -1) {
        this.costMultiSelectOpt.splice(foundIndex, 1);
      }
      this.selectedConfigFieldsForAssignment.splice(index, 1);
      if (this.selectedConfigFieldsForAssignment.length === 0) {
        this.selectedSheets = [];
      }
    },

    removeSelectedSheet(index) {
      try {
        this.selectedSheets.splice(index, 1);
      } catch (e) {
      }
    },

    submitAssignToConfigField() {

      console.log('selectedConfigFieldsForAssignment', this.selectedConfigFieldsForAssignment)

      // if (this.selectedConfigFieldsForAssignment.length <= 0){
      //   this.$store.dispatch('error', {message: 'Please assign a system config field to the selected rekening number', showSwal: true})
      //   return
      // }


      console.log('payload', this.selectedConfigFieldsForAssignment)

      const configFieldsForAssignment = this.selectedConfigFieldsForAssignment.map(e => {
        e['sheets'] = this.selectedSheets
        return e;
      })


      const payload = {
        'data': configFieldsForAssignment,
        'type': 'rek-sch',
        'recordIndex': this.selectedRecordIndex,
      }

      this.$store.dispatch('attachRekNumToConfig', payload)

    },

    assignConfigField(item) {

      this.selectedRecord = item//this.tableData[index]
      console.log('assigned to ', item['assigned_to']);
      this.selectedRecordIndex = item['index'];

      this.selectedConfigFieldsForAssignment = [];
      this.selectedProviderId = '';
      this.selectedTaxField = {};
      this.selectedCalcField = {};
      this.calcOrTaxSelected = null;
      this.showProviderConfigType = false
      this.selectedConfigType = '';
      this.selectedSheets = [];

      if (item['assigned_to']) {
        this.selectedConfigFieldsForAssignment = item['assigned_to'];
        if (this.selectedConfigFieldsForAssignment.length > 0) { /// set already assigned configs into the modal if the configuration has been previously assigned

          if (this.selectedConfigFieldsForAssignment[0]['sheets']) {
            this.selectedConfigFieldsForAssignment[0]['sheets'].forEach(e => this.selectedSheets.push(e))
          }

        }

      }


      this.$bvModal.show('modal')
    },

    handleExcelSubmit() {

      const files = this.$refs.file.getAcceptedFiles();
      console.log('files: ', files)

      if (files.length < 1) {
        this.$store.dispatch('error', {
          message: 'Please select a file',
          showSwal: true
        })
        return;
      }

      const file = files[0];

      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'rek-sch');

      this.$store.dispatch('setUnit4Config', formData).then(() => {
        this.$refs.file.removeAllFiles();
      })

    }
  },
  created() {
    console.log('rek-sch mounted')
    this.$store.dispatch('getSelectedUnit4Config', 'rek-sch')
    /// fetch provider configurations
    this.$store.dispatch('fetchProviders')
  }
}
</script>

<style scoped>
>>> .multiselect__content-wrapper {
  /*width: 500px;*/
  /*position: fixed;*/
  /*top: 43%;*/
}

</style>