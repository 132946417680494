<template>
  <div class="inbox-leftbar">

    <div class="mail-list mt-4">

      <template v-for="(config, index) in configMenus">
        <div class="media mb-4" style="cursor:pointer;" :class="{'active-driver': index === activeIndex}"  @click="configSelected(config, index)">
          <div class="position-relative">
            <template v-if="index === activeIndex">
              <b-avatar size="md" variant="light" class="text-dark"
              ><i class="fa fa-check "></i></b-avatar
              >
            </template>
            <template v-else>
              <b-avatar size="md" variant="dark" class="text-light"
              ><span class="">{{ config['name'] | initials }}</span></b-avatar
              >
            </template>

          </div>
          <div class="media-body">
            <h5 class="mt-0 mb-0 ml-2 font-14">
              {{config['name']}}
              <!--                <template v-if="driver.countPendingFiles && driver.countPendingFiles > 0">-->
              <!--                  <span class="badge badge-soft-danger float-right ml-2"  @click="driverSelected(driver, index)">{{driver.countPendingFiles}}</span>-->
              <!--                </template>-->
            </h5>
            <p class="mt-1 mb-0 ml-2 text-muted font-14">
              <span class="w-75">Click to configure</span>
            </p>
          </div>
        </div>
      </template>

    </div>


  </div>
</template>


<script>

import { Skeleton } from 'vue-loading-skeleton';


/**
 * Side-panel component
 */
export default {

  components:{
    Skeleton
  },
  data() {
    return {
      activeIndex: 0,
      configMenus: [
        {
          'name': 'REKENING SCHEMA',
          'id': 'rek_sch'
        },
        {
          'name': 'DEBITEUR NUMBER',
          'id': 'deb_num'
        },
        {
          'name': 'DAGBOEK SOORT',
          'id': 'dag_soort'
        },
        {
          'name': 'BETALINGS CONDITIE',
          'id': 'bet_cond'
        },
      ]
    };

  },
  computed: {
  },
  methods: {
    configSelected(config, index){
      this.activeIndex = index;
      this.$emit('selected', {
        name: config['name'],
        id: config['id'],
        index,
      })
    }
  },
  created() {
    if(this.configMenus){
        if(this.configMenus.length > 0){
          this.configSelected(this.configMenus[0], 0);
        }
    }

    this.$store.dispatch('setPageTitle', 'Unit 4 configurations')
  },
};
</script>

<style scoped>
.active-driver {
  /*background-color: #f3f7f9;*/
  /*border-color: #f3f7f9;*/
  /*padding-top: 10px;*/
  /*padding-bottom: 10px;*/
  /*!*padding-left: 1px;*!*/
  /*!*padding-right: 1px;*!*/
  /*border-radius: 5px;*/
}
</style>