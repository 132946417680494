<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <form action="#" @submit.prevent="handleExcelSubmit">
          <div class="form-group mt-3">
            <vue-dropzone
                id="file"
                ref="file"
                :use-custom-slot="true"
                :options="dropzoneOptions"
            ><div class="dz-message needsclick">
              <i class="h1 text-muted ri-upload-cloud-2-line"></i>
              <h3>Drop csv / xlsx file</h3>
            </div>
            </vue-dropzone>
          </div>

          <div class="text-center">
            <button type="submit" class="btn btn-dark">Submit Now</button>
          </div>
        </form>
      </div>
    </div>
    <hr>
    <template v-if="configs">
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="row mb-2">
            <div class="col-sm-6 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Display&nbsp;
                  <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select
                  >&nbsp;items
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-6 col-md-6">
              <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <b-table
              table-class="table table-centered w-100"
              thead-tr-class="bg-light"
              :items="tableData"
              :fields="fields"
              :responsive="true"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >

          </b-table>
          <div class="row">
            <div class="col">
              <div
                  class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded">
                  <!-- pagination -->
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data(){
    return {
      dropzoneOptions: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".csv,.xlsx",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 1,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "registeredAt",
      sortDesc: false,
      fields: [],
      tableData: [],
    }
  },
  computed: {
    configs(){
      const data =  this.$store.getters.GET_SELECTED_UNIT4_CONFIG
      console.log('config data', data)
      if(data){
        const header = data.header
        const body = data.body
        this.fields = header.map((e) => {
          //{"index":0,"name":"Rekening","tag":"rekening"}
          return {
            label: e['name'],
            key: e['tag'],
            sortable: true,
          }
        })

        this.tableData = body.map((row) => {

          //{ age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },

          let obj = {};

          for (let i = 0; i < row.length; i++) {
            const cell = row[i];
            const key = cell['tag']
            obj[key] = cell['value']
          }

          return obj;


        })

        //console.log('tableData', this.tableData)

      }

      return data;
    },
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleExcelSubmit(){

      const files = this.$refs.file.getAcceptedFiles();
      console.log('files: ', files)

      if(files.length < 1){
        this.$store.dispatch('error', {
          message: 'Please select a file',
          showSwal: true
        })
        return;
      }

      const file = files[0];

      const formData = new FormData()
      formData.append('file',file)
      formData.append('type', 'bet-cond');

      this.$store.dispatch('setUnit4Config', formData).then(() => {
        this.$refs.file.removeAllFiles();
      })

    }
  },
  created() {
    console.log('bet-cond created')
    this.$store.dispatch('getSelectedUnit4Config', 'bet-cond')
  }
}
</script>

<style scoped>

</style>